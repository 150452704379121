<script setup lang="ts">
import type { NavigationTab } from '@/types/layout';
import StatsPanel from '@/components/StatsOverview/StatsPanel.vue';
import { useAuthStore } from '@/stores/auth';
import { useTournamentStore } from '@/stores/tournaments';

const { loggedIn, showTrialReminder } = storeToRefs(useAuthStore());
const { getWeeklyTournament } = useTournamentStore();

await useAsyncData('tournament', () => getWeeklyTournament());

const tabs: NavigationTab[] = [
  {
    name: 'The Weekly Tournament',
    url: '/tournament',
    icon: 'tournaments'
  },
  {
    name: 'Handle Stats',
    url: '/statistic',
    icon: 'stats'
  },
  {
    name: 'Improve your game',
    url: '/courses',
    icon: 'courses'
  }
];
</script>

<template>
  <AppContainer>
    <AppHeader />
    <div class="bg-brand-navy-600 pt-5 md:pt-0">
      <Container class-name="grid gap-9">
        <TrialReminder v-if="showTrialReminder" />
        <StatsPanel v-if="loggedIn" />
        <AppNavigation :tabs="tabs" />
      </Container>
    </div>
    <div class="h-full flex-1 bg-brand-white pb-16 mb-auto">
      <slot />
    </div>
    <BridgeBanner />
    <AppFooter />
  </AppContainer>
</template>
